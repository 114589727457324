<template>
    <el-container class="page__main">
        <el-header class="flex align-center">
            <div class="page_title">人员导入后台</div>
            <div class="flex-item flex align-center justify-center">
                <el-menu
                        :default-active="$route.path"
                        router
                        mode="horizontal"
                        text-color="#fff"
                        background-color="#353539"
                        active-text-color="#EF5C2E"
                >
                    <el-menu-item index="/org-people">
                        <template>人员管理</template>
                    </el-menu-item>
                    <!-- <el-menu-item index="/protective-case">
                        <template>防护情况</template>
                    </el-menu-item> -->
                    <!-- <el-submenu index="">
                        <template slot="title">积分商城管理</template>
                        <el-menu-item index="/org-goods/provider">
                            供应方管理
                        </el-menu-item>
                        <el-menu-item index="/org-goods/goods">
                            商品管理
                        </el-menu-item>
                        <el-menu-item index="/org-goods/exchange-admin">
                            兑换管理员
                        </el-menu-item>
                    </el-submenu> -->
                </el-menu>
            </div>
            <el-dropdown @command="handleCommand" style="margin-right: 30px">
                <div>
          <span class="el-dropdown-link" style="font-size: 18px; cursor: pointer; color: white">
            {{ username }}
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item :command="'userCenter'">修改密码</el-dropdown-item> -->
                    <el-dropdown-item :command="'logout'">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </el-header>
        <el-main>
            <router-view/>
        </el-main>
        <user-center v-if="centerVisible" ref="userCenter"></user-center>
    </el-container>
</template>

<script>
    import userCenter from './user/user-center.vue'

    export default {
        components: {
            userCenter,
        },
        data() {
            return {
                centerVisible: false,
                username: '',
            }
        },
        created() {
            let username = this.$cookie.get('uname')
            if (username) {
                this.username = username
            }
        },
        methods: {
            handleUserCenter() {
                this.centerVisible = true
                this.$nextTick(() => {
                    this.$refs.userCenter.init()
                })
            },
            handleCommand(command) {
                let that = this
                switch (command) {
                    case 'userCenter':
                        console.log(that.$route)
                        that.handleUserCenter()
                        break
                    case 'logout':
                        that.logout()
                        break
                    default:
                        console.error('未知的命令')
                }
            },
            /**
             * 退出登录
             */
            async logout() {
                let that = this
                that.$message.success('退出成功')
                that.$cookie.delete('Authorization')
                that.$cookie.delete('uid')
                that.$cookie.delete('uname')
                this.$router.push({
                    name: 'Login',
                })
            },
        },
    }
</script>

<style scoped>
    .page__main {
        width: 100%;
        height: 100%;
    }

    .page__main .el-header {
        background-color: #353539;
        color: white;
    }

    .el-main {
        padding: 0px;
    }

    .el-container .page_title {
        font-size: 20px;
    }
</style>

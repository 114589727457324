<template>

    <el-tabs v-model="activeName" @change="onTabChange">
        <el-tab-pane label="兑换详情" name="tableExchangeRef">
            <mix-table
                    ref="tableExchangeRef"
                    :fetch="exchangeTableOptions.fetch"
                    :columns="exchangeTableOptions.columns"
                    :parent-params="exchangeTableOptions.params"
                    :is-selected="exchangeTableOptions.isSelected"
                    :search-control="exchangeTableOptions.searchControl"
                    :need-pagination="exchangeTableOptions.needPagination">
                <template v-slot:opt>
                    <el-table-column
                            label="操作"
                            width="200"
                            fixed="right"
                            align="left"
                    >
                        <template slot-scope="scope" v-if="scope.row.status != 3 && scope.row.status != 4">
                            <el-button type="text" style="color: #1890ff"
                                       @click="goodsDrawUpdate(scope.row,'tableExchangeRef')">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </mix-table>
        </el-tab-pane>
        <el-tab-pane label="抽奖详情" name="tableRef">
            <mix-table
                    ref="tableRef"
                    :fetch="lotteryTableOptions.fetch"
                    :columns="lotteryTableOptions.columns"
                    :parent-params="lotteryTableOptions.params"
                    :auto-load="false"
                    :is-selected="lotteryTableOptions.isSelected"
                    :search-control="lotteryTableOptions.searchControl"
                    :need-pagination="lotteryTableOptions.needPagination">
                <template v-slot:opt>
                    <el-table-column
                            label="操作"
                            width="200"
                            fixed="right"
                            align="left"
                    >
                        <template slot-scope="scope" v-if="scope.row.status != 3 && scope.row.status != 4">
                            <el-button type="text" style="color: #1890ff" @click="goodsDrawUpdate(scope.row)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </mix-table>
        </el-tab-pane>
        <!-- 编辑小弹窗 -->
        <goods-draw-dialog ref="dialogRef"/>
    </el-tabs>
</template>

<script>
    /* 实物兑换记录 */
    import MixTable from "../../../components/mix-table/index";
    import GoodsDrawDialog from "../dialog/goods-draw-dialog";

    export default {
        name: "goods-exchange-flow",
        components: {GoodsDrawDialog, MixTable},
        watch:{
            activeName(newV,oldV){
                this.$refs[newV] && this.$refs[newV].reset()
            }
        },
        data() {
            const {query} = this.$route
            return {
                activeName: 'tableExchangeRef',
                exchangeTableOptions: {
                    fetch: '/api/user-product/web/list',
                    params: {...query, type: 0},
                    columns: [
                        {prop: 'userName', label: '兑换人', searchParams: 'userName', search: true},
                        {
                            prop: 'status',
                            label: '兑换状态',
                            formatter: (row) => this.showStatus(row.status),
                            filterParams: 'exchangeStatus',
                            filters: [{label: '未发放', value: 0}, {label: '已发放', value: 1}, {
                                label: '发放失败',
                                value: 2
                            }, {label: '未领取', value: 3}]
                        },
                        {prop: 'createTime', label: '兑换时间',timeParams:['startTime','endTime']},
                        {prop: 'updateTime', label: '修改时间', formatter: (row) => row.updateTime || '----'},
                    ],
                    needPagination: true, // 开启分页
                    searchControl: true, // 搜索重置按钮
                    isSelected: false, // 多选回调
                },
                lotteryTableOptions: {
                    fetch: '/api/user-product/web/list',
                    params: {...query, type: 1},
                    columns: [
                        {prop: 'userName', label: '抽奖人', searchParams: 'userName', search: true},
                        {
                            prop: 'status',
                            label: '抽奖状态',
                            formatter: (row) => row.status == 4 ? '未抽中' : '已中奖',
                            filterParams: 'drawStatus',
                            filters: [{label:'未抽中',value:0},{label:'已中奖',value:1}]
                        },
                        {prop: 'createTime', label: '抽奖时间'},
                        {
                            prop: 'status',
                            label: '兑换状态',
                            formatter: (row) => this.showStatus(row.status),
                            filterParams: 'exchangeStatus',
                            filters: [{label: '未发放', value: 0}, {label: '已发放', value: 1}, {
                                label: '发放失败',
                                value: 2
                            }, {label: '未领取', value: 3}]
                        },
                        {prop: 'drawExchangeTime', label: '兑换时间', formatter: (row) => row.drawExchangeTime || '----',},
                        {prop: 'updateTime', label: '修改时间', formatter: (row) => row.updateTime || '----'},
                    ],
                    needPagination: true, // 开启分页
                    searchControl: true, // 搜索重置按钮
                    isSelected: false, // 多选回调
                }
            }
        },
        methods: {
            onTabChange(e){
                console.log(e)
            },
            showStatus(status) {
                if (status == 0) {
                    return '未发放'
                } else if (status == 1) {
                    return '已发放'
                } else if (status == 2) {
                    return '发放失败'
                } else if (status == 3) {
                    return '未领取'
                } else {
                    return '----'
                }
            },

            /* 兑奖更新 */
            goodsDrawUpdate(row, tableRef = 'tableRef') {
                const ids = [row.id]
                this.$refs.dialogRef.open({
                    title: '编辑',
                    params: {ids},
                    callback: () => {
                        this.$refs[tableRef].refresh()
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
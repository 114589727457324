import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const _import = require('./import-development')

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/login',
      component: _import('login')
    },
    {
      path: '/',
      redirect: '/org-people',
      component: _import('main'),
      children: [
        {
          path: '/org-people',
          component: _import('org-people/org-people')
        },
        {
          path: '/org-layer',
          component: _import('org-layer/org-layer')
        },
        {
          path: '/protective-case',
          component: _import('protective-case/protective-case')
        },
        {
          path: '/org-goods/goods',
          component: _import('org-goods/goods')
        }, {
          path: '/org-goods/provider',
          component: _import('org-goods/goods-provider')
        }, {
          path: '/org-goods/exchange-admin',
          component: _import('org-goods/exchange-admin')
        },
        {
          path: '/org-goods/goods-exchange',
          component: _import('org-goods/goods-exchange')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  console.log('路由跳转', to)
  if (to.path === '/login') {
    const token = to.query.token
    const name = to.query.name
    const uid = to.query.uid
    if (token && name && uid) {
      Vue.cookie.set('Authorization', token)
      Vue.cookie.set('uid', uid)
      Vue.cookie.set('uname', name)
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    let authorization = Vue.cookie.get('Authorization')
    // console.log('认证', authorization)
    if (authorization == null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
})

export default router

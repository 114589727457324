import Vue from 'vue'
import VueCookie from 'vue-cookie'
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import router from './router'
// import vueResource from "vue-resource"
import validator from 'vue-validator'
import moment from 'moment'
import './flex.css'
import './normalize.css'
import './static/css/common.css'
import http from './utils/http.js'
import App from './App.vue'
Vue.config.productionTip = false

import './theme/index.css'

Vue.filter('dateFormat', function(value, formatStr) {
  return moment(value).format(formatStr)
})

Vue.use(VueCookie)
// Vue.use(vueResource);
Vue.use(validator)
Vue.use(elementUI)
Vue.prototype.$http = http

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

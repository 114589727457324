<template>
  <div class="page-main">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="padding-bottom: 15px">
      <el-breadcrumb-item>防护情况</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-body">
      <div class="flex" style="margin-bottom: 20px">
        <div class="flex align-center">
          <span style="margin-left: 10px; font-size: 15px">姓名</span>
          <el-input style="width: 140px; margin-left: 10px" size="small" v-model="search.name"></el-input>
        </div>
        <div class="flex align-center">
          <span style="margin-left: 20px; font-size: 15px">小程序注册情况</span>
          <el-select
            style="width: 140px; margin-left: 10px"
            size="small"
            v-model="search.register"
            placeholder="请选择"
          >
            <el-option label="已注册" :value="1"> </el-option>
            <el-option label="未注册" :value="2"> </el-option>
          </el-select>
        </div>
        <div class="flex align-center">
          <span style="margin-left: 20px; font-size: 15px">国家反诈中心App绑定情况</span>
          <el-select style="width: 140px; margin-left: 10px" size="small" v-model="search.appBind" placeholder="请选择">
            <el-option label="已绑定" :value="1"> </el-option>
            <el-option label="未绑定" :value="2"> </el-option>
          </el-select>
        </div>
        <div class="flex align-center">
          <span style="margin-left: 20px; font-size: 15px">防骗码颜色</span>
          <el-select style="width: 140px; margin-left: 10px" size="small" v-model="search.level" placeholder="请选择">
            <el-option v-for="(item, index) in levelList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px" class="flex">
          <el-button type="primary" size="small" @click="clickSearch">搜索</el-button>
          <el-button size="small" @click="resetForm">重置</el-button>
        </div>
        <div class="flex-item flex justify-end" style="padding-right: 10px">
          <el-button type="primary" size="small" @click="exportUser">导出</el-button>
        </div>
      </div>
      <div class="flex direction-column">
        <el-table :data="tableData" v-loading="tableLoading">
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="layerName" label="部门"></el-table-column>
          <el-table-column label="小程序注册情况">
            <template slot-scope="scope">
              {{ scope.row.register == 1 ? '已注册' : '未注册' }}
            </template>
          </el-table-column>
          <el-table-column label="国家反诈中心App绑定情况">
            <template slot-scope="scope">
              {{ scope.row.appBind == 1 ? '已绑定' : '未绑定' }}
            </template>
          </el-table-column>
          <el-table-column prop="level" label="防骗码颜色" :formatter="formatCodeName"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="flex direction-row pagination-box align-center">
      <div class="flex-item" style="font-size: 14px">共{{ pageTotal }}条</div>
      <el-pagination
        background
        layout="prev, pager, next,jumper"
        :current-page.sync="page.pageNum"
        :page-size="page.pageSize"
        :total="pageTotal"
        @current-change="selectPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import base from '../../utils/base.js'
export default {
  data() {
    return {
      uid: this.$cookie.get('uid'),
      tableLoading: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      search: {},
      tableData: [],
      levelList: [
        {
          label: '红码',
          value: 1,
        },
        {
          label: '黄码',
          value: 2,
        },
        {
          label: '绿码',
          value: 3,
        },
        {
          label: '未答题',
          value: 4,
        },
      ],
      loading: null,
    }
  },
  async mounted() {
    this.reload()
  },
  methods: {
    async reload() {
      this.tableLoading = true
      const { data: res } = await this.$http.get('/api/org/user/protection', { params: this.getQuerParam() })
      this.tableLoading = false
      if (res.code != 200) {
        this.$message.error(res.msg)
        return
      }
      this.tableData = res.data.records
      this.pageTotal = res.data.total
    },
    selectPage(currentPage) {
      this.page.pageNum = currentPage
      this.reload()
    },
    formatCodeName(row) {
      let level = row.level
      if (level == 1) {
        return '红码'
      } else if (level == 2) {
        return '黄码'
      } else if (level == 3) {
        return '绿码'
      } else {
        return '未答题'
      }
    },
    clickSearch() {
      this.page.pageNum = 1
      this.reload()
    },
    resetForm() {
      this.search = {}
    },
    getQuerParam() {
      let query = Object.assign({}, this.search)
      query.pageNum = this.page.pageNum
      query.pageSize = this.page.pageSize
      query.orgAccId = this.uid
      return query
    },
    async exportUser() {
      //导出表格
      this.loading = base.loading(this)
      const { data: res } = await this.$http.get('/api/org/user/export', { params: this.getQuerParam() })
      this.loading.close()
      if (res.code != 200) {
        this.$message.error(res.msg)
        return
      }
      window.open(res.data)
    },
  },
}
</script>

<style scoped>
.page-main {
  padding: 20px;
  min-height: 100%;
  min-width: 1000px;
  background: #ededed;
  box-sizing: border-box;
}
.main-body {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}
.pagination-box {
  background-color: white;
  border-radius: 20px;
  padding: 12px 20px;
  margin-top: 20px;
}
</style>

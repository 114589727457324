<template>
  <el-dialog
    width="660px"
    title="修改密码"
    :visible.sync="innerVisible"
    append-to-body
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
  >
    <div class="content">
      <el-form ref="updateForm" :model="updateForm" :rules="updateFormRules" label-width="120px" label-position="right">
        <el-form-item label="用户名">{{ username }}</el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            :type="showNewPwd ? 'text' : 'password'"
            class="common__input_default"
            v-model="updateForm.newPassword"
            style="width: calc(100% - 80px);"
            placeholder="请输入新密码"
          >
            <img
              :src="showNewPwd ? '../../static/image/show.png' : '../../static/image/hide.png'"
              slot="suffix"
              alt
              style="margin: 12px 10px; cursor: pointer"
              @click="showNewPwd = !showNewPwd"
            />
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passwordCheck">
          <el-input
            :type="showNewPwdCheck ? 'text' : 'password'"
            class="common__input_default"
            v-model="updateForm.passwordCheck"
            style="width: calc(100% - 80px);"
            placeholder="请再次输入密码"
          >
            <img
              :src="showNewPwdCheck ? '../../static/image/show.png' : '../../static/image/hide.png'"
              slot="suffix"
              alt
              style="margin: 12px 10px; cursor: pointer"
              @click="showNewPwdCheck = !showNewPwdCheck"
            />
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <!-- <el-button @click="cancel">取 消</el-button> -->
      <el-button type="primary" style="width: 300px; border-radius: 10px;" @click="upPwd">确认</el-button>
    </div>
  </el-dialog>
  <!-- </el-dialog> -->
</template>

<script>
export default {
  name: 'userCenter',
  components: {},
  mounted() {},
  data() {
	  function passwordValidator(message) {
	  	const regex = /^[A-z0-9]{6,16}$/
	  	return (rule, value, callback) => {
	  		if (regex.test(value)) {
	  			callback()
	  		} else {
	  			callback(new Error(message))
	  		}
	  	}
	  }
    let passwordCheckRule = (rule, value, callback) => {
      if (value != this.updateForm.newPassword) {
        callback(new Error('两次密码不同'))
      }
      callback()
    }
    return {
      outerVisible: false,
      innerVisible: false,
      showOldPwd: false,
      showNewPwd: false,
      showNewPwdCheck: false,
      username: this.$cookie.get('uname'),
      uid: this.$cookie.get('uid'),
      lastPageInfo: {},
      updateForm: {
        newPassword: '',
        passwordCheck: ''
      },
      updateFormRules: {
        newPassword: [
          {
            required: true,
            message: '新密码不能为空',
            trigger: 'blur'
          },
          {
            validator: passwordValidator('只能是6-16位字母或数字'),
            trigger: 'blur'
          }
        ],
        passwordCheck: [
          {
            required: true,
            message: '确认密码不能为空',
            trigger: 'blur'
          },
          {
            validator: passwordCheckRule,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 初始化
    init() {
      this.innerVisible = true
      // this.$nextTick(() => {
      //   this.$refs["dataForm"].resetFields();
      // });
    },
    upPwd() {
      let that = this
      this.$refs.updateForm.validate(async valid => {
        if (valid) {
          const { data: res } = await that.$http.post('/api/org/account/updateInfo', {
            uid: that.uid,
            pwd: that.updateForm.newPassword
          })
          if (res.code == 200) {
            that.$message.success('密码修改成功')
            that.$refs.updateForm.resetFields()
            that.innerVisible = false
            // that.$router.push({
            //   name: this.lastPageInfo.lastName,
            //   params: this.lastPageInfo.lastParams,
            // });
          } else {
            that.$message.error('密码修改失败')
          }
        }
      })
    },
    cancel() {
      this.innerVisible = false
      this.$refs.updateForm.resetFields()
    },
    onBeforeClose(done) {
      this.cancel()
      done()
    }
  }
}
</script>

<style>
/**
   * 默认css 开始
   */
.page__page_name {
  width: 100%;
  height: 100%;
}

.page__page_name .content-box {
  width: 100%;
  height: calc(100% - 50px);
}

.page__page_name .content-box__main {
  margin-left: 1%;
  width: 98%;
  background-color: white;
  padding-top: 40px;
  padding-bottom: 30px;
}

.page__page_name .search-box__condition_input .el-input__inner {
  border: 2px solid #33476a;
  border-radius: 10px;
  height: 42px;
}

.page__page_name .search-box__condition_input .el-input__inner:hover,
.page__page_name .el-input__inner:focus {
  border-color: #33476a;
}

.page__page_name .add-box__input .el-input__inner {
  border-radius: 10px;
  height: 35px;
}

/**
   * 默认css 结束
   */
.body {
  display: flex;
  margin-bottom: 30px;
  overflow: hidden;
  height: 88%;
}
.common-column {
  margin-right: 30px;
  flex-shrink: 0;
}
.duo-column {
  margin-right: 30px;
  word-break: break-all;
  overflow: hidden;
  overflow-y: auto;
}
.common-column:not(:last-child),
.duo-column {
  padding-right: 30px;
  border-right: 1px solid rgba(167, 171, 183, 0.2);
}
</style>
<style scoped>
.el-input__inner {
  border-radius: 10px;
}
</style>

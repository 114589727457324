<template>
    <el-container class="page">
        <el-container class="main-container">
            <el-breadcrumb separator-class="el-icon-arrow-right" style="padding-bottom: 15px">
                <el-breadcrumb-item to="/org-goods/goods">商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>兑换详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-main class="main-body">
                <!-- 实物兑换记录 -->
                <goods-exchange-in-kind v-if="categoryId == 2"></goods-exchange-in-kind>
                <!-- 流量兑换记录 -->
                <goods-exchange-flow v-else-if="categoryId == 1"></goods-exchange-flow>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import MixTable from "../../components/mix-table/index";
    import GoodsExchangeInKind from "./components/goods-exchange-in-kind";
    import GoodsExchangeFlow from "./components/goods-exchange-flow";

    // 商品兑换列表
    export default {
        components: {GoodsExchangeFlow, GoodsExchangeInKind, MixTable},
        data() {
            return {
                categoryId:''
            }
        },
        mounted() {
            const {query} = this.$route
            this.categoryId = query.categoryId
        }
    }
</script>

<style scoped>
    .page {
        width: 100%;
        height: 100%;
    }

    .main-container {
        padding: 25px;
        background: #ededed;
        display: flex;
        flex-direction: column;
    }

    .main-body {
        background-color: white;
        border-radius: 20px;
        padding: 24px 20px;
        box-sizing: border-box;
        font-size: 18px;
    }
    .goods-status{
        position: relative;
        padding-left: 15px;
    }

    .goods-status:before{
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #2cec2c;
    }

    .goods-status_red{
        position: relative;
        padding-left: 15px;
    }

    .goods-status_red:before{
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #FF5A00;
    }
</style>
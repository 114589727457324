import addMember from './add-member.vue'
import ImportUser from './import-user.vue'
export default {
  components: {
    addMember,
    'import-user': ImportUser,
  },
  data() {
    return {
      filterText: '', // 筛选文本
      //树形控件props
      defaultProps: {
        children: 'childLayers',
        label: 'layerName',
        isLeaf: 'leaf',
      },
      breadcrumbItems: [], //顶部面包屑导航数组
      tableData: [], //表格数据
      tableLoading: false, //表格loading显示
      multipleSelection: [], //表格数据选中数组
      adminId: -1, //当前表格数据中管理员id
      currentLayer: {}, //当前选中的部门对象
      adjustDialogVisible: false, //调整部门弹框显示
      adjustLayerId: -1, //调整后的部门id
      page: {
        current: 1,
        size: 10,
        total: 0
      },
      addMemberVisible: false,
      search: {
        name: '',
        phone: '',
      },
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  mounted() {
	  this.getOrgUsers(this.currentLayer.id)
  },
  methods: {
    pathToOrg() {
      this.$router.push({
        path: '/org-layer',
      })
    },
    handleAddMember() {
      this.addMemberVisible = true
      this.$nextTick(() => {
        this.$refs.addMember.init(this.currentLayer.id, this.currentLayer.layerName)
      })
    },
    handleEdit(val) {
      if (this.adminId == val.id) {
        val.isAdmin = true
      } else {
        val.isAdmin = false
      }
      this.addMemberVisible = true
      this.$nextTick(() => {
        this.$refs.addMember.initEdit(val)
      })
    },

    refreshChange() {
      console.log('refreshChange')
	  this.page.current = 1
      this.getOrgUsers(this.currentLayer.id)
    },
    handlerAdjustDialogConfirm() {
      this.changeLayer()
      this.adjustDialogVisible = false
    },
    /**
     * 处理调整部门按钮事件
     */
    handleAdjustDepartment() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请选择需要调整部门的成员')
        return
      }
      this.adjustLayerId = -1
      this.adjustDialogVisible = true
    },
    /**
     * 批量删除提示弹框
     */
    confirmDelete() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先选择人员')
        return
      }
      this.$confirm('确认删除人员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteUsers()
        })
        .catch(() => {})
    },
    /**
     * 调整部门节点选中
     */
    dialogHandleNodeClick(data) {
      this.adjustLayerId = data.id
    },
    /**
     * tree节点筛选方法
     * @returns
     */
    filterNode(value, data) {
      if (!value) return true
      return data.layerName.indexOf(value) !== -1
    },
    /**
     * 树节点点击事件
     * @param {object} data 对应的节点数据
     */
    handleNodeClick(data, node) {
      console.log('handleNodeClick node:', node)
      this.selectLayer(data)
      //根据节点向上遍历获取导航数组
      const curBreadcrumbItems = []
      let curNode = node
      while (curNode.data) {
        curBreadcrumbItems.push(curNode.data)
        curNode = curNode.parent
      }
      this.breadcrumbItems = curBreadcrumbItems.reverse()
      console.log(this.breadcrumbItems)
    },
    /**
     * 选中部门处理逻辑
     * @param {object} layer 部门对象
     */
    selectLayer(layer) {
      this.getOrgUsers(layer.id)
      this.currentLayer = layer
    },
    /**
     * 表格多选状态改变事件
     * @param {array} val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    /**
     * 懒加载左侧菜单叶
     * @param {boolean} flag 是否是弹框的加载数据
     */
    async loadNode(node, resolve, flag) {
      let res
      if (node.level === 0) {
        res = await this.getTreeNode(-1)
        console.log(res)
      } else {
        res = await this.getTreeNode(node.data.id)
        console.log(res)
      }
      if (res) {
        //默认加载第一项数据
        if (!flag && node.level === 0 && res.data.length > 0) {
          let first = res.data[0]
          this.selectLayer(first)
          this.breadcrumbItems = [first]
        }
        return resolve(res.data)
      } else {
        return resolve([])
      }
    },
    /**
     * 获取树节点
     * @param {int} queryId  需要查询的部门列表的上级部门id，无上级为-1
     * @returns
     */
    async getTreeNode(queryId) {
      let res = await this.$http({
        method: 'GET',
        url: '/api/org/layer/layer',
        params: {
          id: queryId,
        },
      })
      if (200 != res.data.code) {
        this.$message.error(res.data.msg)
        return
      }
      return res.data
    },
    /**
     * 获取组织成员
     * @param {int} layerId
     * @returns
     */
    async getOrgUsers(layerId) {
      this.tableLoading = true
      let res = await this.$http(`/api/org/user/special?name=${this.search.name}&phone=${this.search.phone}&pageNum=${this.page.current}&pageSize=${this.page.size}`)
      this.tableLoading = false
      if (200 != res.data.code) {
        this.$message.error(res.data.msg)
        return
      }
      this.tableData = res.data.data.list
	  this.page.total = res.data.data.total
      this.adminId = res.data.data.admin ? res.data.data.admin.id : -1
    },
    resetBtn() {
      this.search = {
        name: '',
        phone: '',
      }
    },
	selectPage() {
		this.getOrgUsers(this.currentLayer.id)
	},
	searchOrgUsers(layerId) {
		this.page.current = 1
		this.getOrgUsers(this.currentLayer.id)
	},
    /**
     * 批量删除用户
     * @returns
     */
    async deleteUsers() {
      const delUidList = []
      for (const i in this.multipleSelection) {
        delUidList.push(this.multipleSelection[i].id)
      }
      let res = await this.$http({
        method: 'DELETE',
        url: '/api/org/user/special',
        data: delUidList,
        params: {
          layerId: this.currentLayer.id,
        },
      })
      if (200 != res.data.code) {
        this.$message.error(res.data.msg)
        return
      }
      //删除之后重新加载数据
	  this.page.current = 1;
      this.getOrgUsers(this.currentLayer.id)
    },
    /**
     * 调整部门接口
     * @returns
     */
    async changeLayer() {
      if (this.adjustLayerId == -1) {
        this.$message.error('请先选择调整后的部门')
        return
      }
      const userIds = this.multipleSelection.map((item) => item.id)
      const body = {
        uidList: userIds,
        layerId: this.adjustLayerId,
      }
      let { data } = await this.$http({
        method: 'PUT',
        url: '/api/org/user/transfer',
        data: body,
        params: {
          layerId: this.currentLayer.id,
        },
      })
      if (200 != data.code) {
        this.$message.error(data.msg)
        return
      }
      this.$message.success('调整成功')
      //调整之后重新加载数据
      this.getOrgUsers(this.currentLayer.id)
    },
    downloadTlp() {
      window.open('/proxy/static/orgSpecial.xlsx')
    },
  },
}

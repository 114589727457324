<template>
    <el-dialog
            :title="title"
            :visible.sync="visible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width="650px"
            center
    >
        <el-form size="small" ref="ruleForm" :model="form" :rules="formRules" label-width="120px"
                 style="width: 80%;margin: 0 auto">
            <el-form-item label="兑换状态" prop="exchangeStatus">
                <el-select v-model="form.exchangeStatus" placeholder="请选择" style="width: 100%">
                    <el-option label="已发放" value="0"></el-option>
                    <el-option label="发放失败" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="失败原因" prop="reason" v-if="form.exchangeStatus ==1">
                <el-input v-model="form.reason"/>
            </el-form-item>
            <el-row type="flex" justify="end" align="center">
                <el-button size="small" plain @click="visible = false">取消</el-button>
                <el-button size="small" :loading="submitting" type="primary" @click="submitForm">{{ submitting ?
                    '提交中' : '确认' }}
                </el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
    /* 商品弹窗 */
    export default {
        name: 'goods-draw-dialog',
        data() {
            return {
                title: '',
                callback: undefined, //回调函数
                visible: false, // 显示详情
                submitting: false, // 提交状态
                form: {},
                formRules: {
                    exchangeStatus: [
                        {required: true, message: '请选择兑换状态', trigger: 'blur'},
                    ],
                    reason: [
                        {required: true, message: '请输入理由', trigger: 'blur'},
                    ],
                },

            }
        },

        methods: {
            /* 提交form表单 */
            submitForm() {
                this.$refs.ruleForm.validate(async (valid) => {
                    if (valid) {
                        this.submitting = true
                        const params = {...this.form}
                        const url = '/api/user-product/web/update'
                        try {
                            const {data: res} = await this.$http({
                                method: 'POST',
                                url: url,
                                data: {...params},
                            })
                            if (res.code === 200) {
                                this.$message.success('操作成功')
                                this.callback && this.callback()
                                this.visible = false
                            } else {
                                this.$message.error(res.msg)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                        this.submitting = false
                    }
                })
            },

            open(payload = {}) {
                const {title = '编辑', params = {}, callback} = payload
                this.callback = callback
                this.title = title
                this.form = {}
                if (Object.keys(params).length > 0) {
                    this.form = Object.assign({}, this.form, params)
                }
                this.visible = true
            },
        }
    }
</script>
<style scoped>
    .upload {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 60px;
        border: 1px dashed #d9d9d9;
        overflow: hidden;
    }

    .upload-img {
        position: relative;
    }

    .icon-delete {
        color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999999999 !important;
        display: none;
    }

    .upload:hover .upload-img:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .upload:hover .icon-delete {
        display: block !important;
    }

</style>
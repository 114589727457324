<template>
  <div style="width: 100%; height: 100%; position: relative" class="flex justify-center align-center">
    <img src="../static/bg.png" alt="" style="width: 100%; height: 100%; position: absolute" />
    <div style="width: 100%; height: 100%; position: absolute" class="flex align-center justify-center">
      <el-card style="width: 400px; border-radius: 20px">
        <div class="flex direction-column">
          <div class="flex justify-center" style="font-size: 30px">人员导入后台</div>
          <el-form :model="form" style="margin-top: 20px">
            <el-form-item>
              <el-input v-model="form.uname" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input type="password" v-model="form.pwd" placeholder="请输入密码"></el-input>
            </el-form-item>
          </el-form>
          <el-button style="height: 50px" type="primary" @click="login">登录</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        uname: '',
        pwd: '',
      },
    }
  },
  mounted() {
    console.log(this.$cookie)
  },
  methods: {
    async login() {
      let res = await this.$http({
        method: 'POST',
        url: '/api/org/account/login/special',
        data: this.form,
      })
      console.log(res)
      if (200 != res.data.code) {
        this.$message.error(res.data.msg)
        return
      }
      this.$cookie.set('Authorization', res.data.data.token)
      this.$cookie.set('uid', res.data.data.id)
      this.$cookie.set('uname', res.data.data.uname)
      console.log('Authorization', this.$cookie.get('Authorization'))
      this.$router.push({
        path: '/',
      })
    },
  },
}
</script>

<style></style>

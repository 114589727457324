<template>
  <el-dialog
    title="添加成员"
    class="addMember"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="50%"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item prop="name" label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item prop="idcard" label="身份证">
        <el-input v-model="form.idcard" maxlength="18"></el-input>
      </el-form-item>
      <el-form-item prop="phone" label="手机号">
        <el-input v-model="form.phone" maxlength="11" :disabled="!isAdd"></el-input>
      </el-form-item>
      <!-- <el-form-item prop="layerId" label="所在部门">
        <el-input v-model="form.layerName" :disabled="true"></el-input>
      </el-form-item> -->
      <el-form-item prop="manage" label="工作所在区">
        <el-cascader
          ref="manageCascader"
          v-model="form.manage"
          :options="manageCommunitys.children"
          clearable
          filterable
          collapse-tags
          size="medium"
        ></el-cascader>
      </el-form-item>
      <el-form-item prop="address" label="工作详细地址">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <!-- <el-form-item prop="occupation" label="职位">
        <el-input v-model="form.occupation"></el-input>
      </el-form-item> -->
      <!-- <el-form-item prop="isAdmin" label="管理员">
        <el-checkbox v-model="form.isAdmin"></el-checkbox>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" style="width: 100px">取消</el-button>
      <el-button v-if="isAdd" type="primary" @click="submit" style="width: 100px">确定</el-button>
      <el-button v-else type="primary" @click="submitEdit" style="width: 100px">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import base from '../../utils/base.js'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        manage: [],
        isAdmin: false
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        idcard: [
          {
            required: true,
            message: '请输入身份证',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        manage: [
          {
            required: true,
            message: '请选择工作所在区',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入工作详细地址',
            trigger: 'blur'
          }
        ]
        // occupation: [
        //   {
        //     required: true,
        //     message: '请输入职位',
        //     trigger: 'blur'
        //   }
        // ]
      },
      manageCommunitys: [],
      isSubmit: false,
      isAdd: true
    }
  },

  methods: {
    async init(layerId, layerName) {
      this.$nextTick(() => {
        this.$refs['form'].resetFields()
      })
      this.isSubmit = false
      this.isAdd = true
      this.form = { manage: [], isAdmin: false }
      this.dialogVisible = true
      this.form.layerId = layerId
      this.form.layerName = layerName
      this.form.isAdmin = false

      let res = null
      res = await this.$http.get(`/api/community/cascader/sx/community`)
      this.manageCommunitys = res.data.data
    },
    async initEdit(data) {
      this.$nextTick(() => {
        this.$refs['form'].resetFields()
      })
      let form = JSON.parse(JSON.stringify(data))
      this.isSubmit = false
      this.isAdd = false
      this.dialogVisible = true
      form.manage = [form.areaId, form.streetId, form.communityId]
      this.form = form

      let res = null
      res = await this.$http.get(`/api/community/cascader/sx/community`)
      this.manageCommunitys = res.data.data
    },
    handleClose(done) {
      console.log('resetFields')
      this.$refs.form.resetFields()
      done()
    },

    async submit() {
      console.log(this.form)
      const result = await base.checkForm(this, 'form')
      if (!result) {
        return
      }
      if (this.isSubmit) {
        return false
      }
      let idCardCheck = this.checkIdCard(this.form.idcard)
      if (!idCardCheck) {
        return
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(this.form.phone)) {
        this.$message.error('手机号格式不正确')
        return false
      }
      var areaLabels = this.$refs['manageCascader'].getCheckedNodes()[0].pathLabels

      this.form.provinceId = 330000
      this.form.province = '浙江省'
      this.form.cityId = 330600
      this.form.city = '绍兴市'
      this.form.areaId = this.form.manage[0]
      this.form.area = areaLabels[0]
      this.form.streetId = this.form.manage[1]
      this.form.street = areaLabels[1]
      this.form.communityId = this.form.manage[2]
      this.form.community = areaLabels[2]

      this.form.hProvinceId = 330000
	  this.form.hProvince = '浙江省'
      this.form.hCityId = 330600
	  this.form.hCity = '绍兴市'
      this.form.hAreaId = this.form.manage[0]
      this.form.hArea = areaLabels[0]
      this.form.hStreetId = this.form.manage[1]
      this.form.hStreet = areaLabels[1]
      this.form.hCommunityId = this.form.manage[2]
      this.form.hCommunity = areaLabels[2]
      this.form.hAddress = this.form.address

      this.isSubmit = true
      let res = await this.$http({
        method: 'POST',
        url: '/api/org/user/add/special',
        data: this.form
      })
      if (200 != res.data.code) {
        this.isSubmit = false
        this.$message.error(res.data.msg)
        return
      }
      this.dialogVisible = false
      this.$emit('refreshDataList')
    },
    async submitEdit() {
      console.log(this.form)
      const result = await base.checkForm(this, 'form')
      if (!result) {
        return
      }
      if (this.isSubmit) {
        return false
      }
      let idCardCheck = this.checkIdCard(this.form.idcard)
      if (!idCardCheck) {
        return
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(this.form.phone)) {
        this.$message.error('手机号格式不正确')
        return false
      }
      var areaLabels = this.$refs['manageCascader'].getCheckedNodes()[0].pathLabels

      this.form.provinceId = 330000
      this.form.province = '浙江省'
      this.form.cityId = 330600
      this.form.city = '绍兴市'
      this.form.areaId = this.form.manage[0]
      this.form.area = areaLabels[0]
      this.form.streetId = this.form.manage[1]
      this.form.street = areaLabels[1]
      this.form.communityId = this.form.manage[2]
      this.form.community = areaLabels[2]

      this.form.hProvinceId = 330000
	  this.form.hProvince = '浙江省'
      this.form.hCityId = 330600
	  this.form.hCity = '绍兴市'
      this.form.hAreaId = this.form.manage[0]
      this.form.hArea = areaLabels[0]
      this.form.hStreetId = this.form.manage[1]
      this.form.hStreet = areaLabels[1]
      this.form.hCommunityId = this.form.manage[2]
      this.form.hCommunity = areaLabels[2]
      this.form.hAddress = this.form.address

      this.isSubmit = true
      let res = await this.$http({
        method: 'PUT',
        url: '/api/org/user/update/special',
        data: this.form
      })
      if (200 != res.data.code) {
        this.isSubmit = false
        this.$message.error(res.data.msg)
        return
      }
      this.dialogVisible = false
      this.$emit('refreshDataList')
    },
    checkIdCard(idCard) {
      console.log(idCard)
      var reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (!reg.test(idCard)) {
        this.$message.error('身份证不合法')
        return false
      }

      var now = new Date()
      var year = Number(idCard.substr(6, 4))
      var month = Number(idCard.substr(10, 2))
      var day = Number(idCard.substr(12, 2))

      var birthday = new Date(year, month, 0)

      if (birthday.getTime() > now.getTime()) {
        this.$message.error('身份证不合法')
        return false
      }
      return true
    }
  }
}
</script>

<style>
.addMember .el-dialog__header {
  text-align: center;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}
</style>

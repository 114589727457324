export default {
	 checkForm(that, form) {
		return  new Promise((resolve, reject) => {
			that.$refs[form].validate((valid) => {
				if (valid) {
					resolve(true)
				} else {
					resolve(false)
				}
			})
		})
	},
	async showDeleteDialog(that, content) {
		return await new Promise((resolve, reject) => {
			that.$confirm(content, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				resolve(true)
			}).catch(() => {
				resolve(false)
			})
		})
	},
	loading(that) {
		const loading = that.$loading({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		})
		return loading
	}
}

<template>
    <div>
        <el-row type="flex" align="middle" justify="space-between" style="flex-wrap: wrap">
            <slot name="header-left" :keys="selectRowKeys"></slot>
            <el-row type="flex" align="middle" style="padding:  6px 0">
                <template v-for="(col,index) in computedColumns">
                    <!-- 文本搜索 -->
                    <mix-search
                            v-if="col.search"
                            :key="index"
                            :value.sync="searchParams[col.searchParams || col.prop]"
                            :label="col.label"
                            type="input"
                            :placeholder="'搜索'+col.label"
                            @keyup.enter.native="reload()"
                            @clear="reload()"
                    />
                    <!-- 筛选搜索 -->
                    <mix-search
                            v-else-if="col.filters"
                            :key="index"
                            :value.sync="filterParams[col.filterParams || col.prop]"
                            :label="col.label"
                            placeholder="请选择"
                            type="select"
                            :options="col.filters"
                            @change="reload()"
                    />
                    <!-- 时间范围选择 -->
                    <mix-search
                            v-else-if="col.timeParams"
                            :key="index"
                            :start.sync="timeParams[col.timeParams[0]]"
                            :end.sync="timeParams[col.timeParams[1]]"
                            :label="col.label"
                            :time-format="'time'"
                            :placeholder="['开始日期','结束日期']"
                            type="datetimerange"
                            @change="reload()"
                    />
                </template>
                <div v-if="searchControl">
                    <el-button size="small" type="primary" @click="reload()">搜索</el-button>
                    <el-button size="small" @click="reset">重置</el-button>
                </div>
            </el-row>
            <slot name="header-right" :keys="selectRowKeys"></slot>
        </el-row>
        <el-table
                ref="tableRef"
                v-loading="loading"
                :size="size"
                :stripe="stripe"
                :data="list"
                :row-class-name="rowClass"
                :header-cell-style="headRowStyle"
                :header-cell-class-name="headCellClass"
                :border="border"
                @selection-change="onSelectionChange"
        >
            <el-table-column
                    class="mix-table__selection"
                    v-if="isSelected"
                    type="selection"
                    align="center"
                    width="60"
            />
            <!-- 展开行插槽 -->
            <slot name="expand"/>
            <template v-for="(col,index) in computedColumns">
                <!-- 复杂情况可使用自定义ui 渲染 在列表中配置slot对应即可 -->
                <slot v-if="col.slot" :name="col.slot"/>
                <el-table-column
                        v-else
                        :key="index"
                        :prop="col.prop"
                        :label="col.label"
                        :min-width="col.minWidth"
                        :width="col.width"
                        :fixed="col.fixed"
                        :resizable="false"
                        :show-overflow-tooltip="typeof col.formatter === 'string' && col.formatter === 'ellipsis'"
                        filter-placement="bottom"
                        :sortable="col.sortable?'custom':false"
                        :align="col.align"
                        :column-key="col.filterParams||col.prop"
                        :formatter="typeof col.formatter === 'string'?row=>columnFormat(col.formatter,row[col.prop]):col.formatter"
                />
            </template>
            <!-- 自定义操作区间 使用原有写法 -->
            <slot name="opt"/>
        </el-table>
        <el-row v-if="needPagination" type="flex" justify="end" align="middle" class="mix-table__pagination">
            <el-pagination
                    layout="total ,prev, pager,next ,jumper"
                    :current-page.sync="pageParams.pageNum"
                    :total="total"
                    background
                    :page-size="pageParams.pageSize"
                    @current-change="onPageChange"
                    @size-change="onPageSizeChange"
            />
        </el-row>
        <el-row v-else type="flex" justify="end" align="middle" class="mix-table__pagination">
            <el-pagination
                    layout="total"
                    :current-page.sync="pageParams.pageNum"
                    :total="list.length"
                    background
                    :page-size="pageParams.pageSize"
            />
        </el-row>
    </div>
</template>

<script>
    /**
     * @Component mix-table 集搜索、筛选、排序、分页等功能为一体的列表组件
     * @Prop size 表格大小 同el-table size
     * @Prop stripe 斑马纹显示 同el-table stripe
     * @Prop pageSize 初始显示条数
     * @Prop isSelected 是否支持选中
     * @Prop title 表格名称 （数据导出时 excel名称）
     * @Prop fetch 数据请求接口 可以传字符串
     * @Prop del-fetch 删除数据请求接口 可以传字符串
     * @Prop del-key 删除接口参数key
     * @Prop parentParams 父类参数传递 附加到fetch 数据接口上
     */
    import MixSearch from '../mix-search'
    import moment from "moment";

    export default {
        name: "mix-table",
        components: {
            MixSearch
        },
        props: {
            /* 搜索 重置按钮 */
            searchControl: {
                type: Boolean,
                default: false,
            },

            border: {
                type: Boolean,
                default: false,
            },
            /* 自动加载  默认启动 */
            autoLoad: {
                type: Boolean,
                default: true
            },
            size: {
                type: String,
                default: 'medium',
                validator(value) {
                    return ['medium', 'small', 'mini'].indexOf(value) !== -1
                }
            },
            /* 斑马纹显示 */
            stripe: {
                type: Boolean,
                default: false
            },

            pageSize: {
                type: Number,
                default: 10
            },
            /* 是否选择 若开启默认开启多选 */
            isSelected: {
                type: Boolean,
                default: false
            },
            /* 列表默认多选 */
            multiple: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: 'mix-table',
            },
            fetch: {
                type: [Function, String],
            },
            /**
             * prop 同el-table
             * label 同el-table
             * minWidth 同el-table
             * fixed 同el-table
             * width 同el-table
             * formatter {Function}  (row) => return String||JSX 自定义列显示
             * sortable {Boolean} 是否字段开启排序
             * align 同el-table
             **/
            columns: {
                type: Array,
                default: () => []
            },
            delFetch: {
                type: [Function, String],
            },
            delKey: {
                type: String,
                default: 'id'
            },
            parentParams: {
                type: Object,
                default: () => {
                }
            },
            /* 表格行自定义样式类型 */
            rowClass: {
                type: Function,
                default: () => {
                    return ''
                }
            },
            /* 表格表头style 设置 */
            headRowStyle: {
                type: Object,
                default: () => ({background: '#fff', color: '#556472', fontWeight: 'bold'})
            },
            /* 是否需要分页 */
            needPagination: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            /* 参数计算 这里参数同名会被覆盖，使用时请注意这个问题 */
            params() {
                let {parentParams, sortParams, filterParams, searchParams, pageParams, timeParams} = this;
                let timeObj = {}
                if (Object.keys(timeParams).length > 0) {
                    Object.keys(timeParams).map(item => {
                        if (timeParams[item]) {
                            timeObj[item] = moment(timeParams[item] * 1000).format('YYYY/MM/DD hh:mm')
                            // timeObj[item] = new Date(timeParams[item] * 1000)
                        }
                    })
                }
                let p = {...sortParams, ...filterParams, ...searchParams, ...timeObj, ...parentParams}
                if (this.needPagination) {
                    p = {...p, pageNum: pageParams.pageNum, pageSize: pageParams.pageSize}
                }
                return p
            },
            /* 列变化时重新渲染 */
            computedColumns() {
                return this.columns
            },
        },

        watch: {
            loaded(newV, oldV) {
                if (newV && !oldV) {
                    // 首次加载完成回调
                    this.$emit('load')
                }
            }
        },

        data() {
            const pageSize = this.pageSize
            return {
                loaded: false, // 首次加载完成
                loading: false, // 加载中状态
                list: [], // 数据
                total: 0, // 数目
                pageParams: {
                    pageNum: 1, // 页码
                    pageSize, // 每页条数
                },
                sortParams: {}, // 排序参数
                timeParams: {},//日期筛选
                filterParams: {}, // 筛选排序
                searchParams: {}, // 搜索排序
                selectRowKeys: [], // 已选列记录
            }
        },

        mounted() {
            if (this.autoLoad) {
                this.fetchData()
            }
        },

        methods: {
            /* 请求接口数据 */
            async fetchData(reload = false) {
                if (!this.fetch || this.loading) return;
                this.loading = true
                const params = this.params;
                try {
                    const {data: res} = typeof this.fetch === 'function' ? await this.fetch(params) : await this.$http(
                        {
                            url: this.fetch,
                            params: params,
                            methods: 'GET'
                        }
                    )
                    if (this.needPagination) {
                        const {list, total} = res.data
                        // 重载机制 非首页 有数据且当前页无数据 直接reload
                        if (params.pageNum !== 1 && list.length === 0 && total > 0) {
                            reload = true
                        } else {
                            this.list = list
                            this.total = total || 0
                        }
                    } else {
                        this.list = res.data
                        this.total = res.data.length
                    }
                    this.selectRowKeys = [] // 清空选择
                    this.loaded = true
                } catch (e) {
                    console.log(e)
                }
                this.loading = false
                if (reload) {
                    this.reload()
                }
            },

            /* 刷新当前页 */
            refresh() {
                this.fetchData()
            },

            /* 重载初始页 */
            reload() {
                this.pageParams = Object.assign({}, this.pageParams, {pageNum: 1})
                this.fetchData()
            },

            /* 条件重置 */
            reset() {
                this.sortParams = {} // 排序参数
                this.filterParams = {}// 筛选排序
                this.searchParams = {}// 搜索排序
                this.timeParams = {}
                this.reload()
            },

            /* 分页变化 */
            onPageChange(page) {
                this.$set(this.pageParams, 'pageNum', page)
                this.refresh()
            },

            /* 分页变化 */
            onPageSizeChange(size) {
                this.$set(this.pageParams, 'pageSize', size)
                this.refresh()
            },

            /* 选中回调 */
            onSelectionChange(val) {
                if (this.multiple) {
                    // 多选
                    this.selectRowKeys = val
                } else {
                    // 单选
                    if (val.length > 1) {
                        this.$refs.tableRef.clearSelection();
                        this.$refs.tableRef.toggleRowSelection(val.pop())
                    } else {
                        this.selectRowKeys = val
                    }
                }
            },

            /* 数据格式化显示，结合全局filter使用 */
            columnFormat(formatter, value) {
                return this.$options.filters[formatter](value) || ''
            },

            headCellClass(row) {
                if (row.columnIndex === 0 && !this.multiple) {
                    return 'disableheadselection'
                }
            }


        }
    }
</script>

<style scoped>
    /* mix-table__pagination 分页器 */
    .mix-table__pagination {
        padding-top: 6px;
    }

    /* 单选模式下样式 */
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }

</style>
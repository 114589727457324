<template>
    <div style="display: flex;align-items: center ;font-size: 14px;padding:4px 0;margin-right: 30px">
        <span style="color:#606266;font-weight: bold;text-align: left;margin-right: 4px">{{ `${label}：` }}</span>
        <el-input
                v-if="type === 'input'"
                v-model="val"
                :placeholder="placeholder"
                :size="size"
                clearable
                :style="{width:width + 'px'}"
                @clear="$emit('clear')"
        >
        </el-input>
        <el-select v-else-if="type === 'select'" v-model="val" clearable :size="size" :placeholder="placeholder"
                   :style="{width:width + 'px'}" @change="$emit('change')">
            <el-option
                    v-for="item in filterOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
            />
        </el-select>
        <el-date-picker
                v-else-if="type === 'datetimerange'"
                v-model="time"
                prefix-icon="-"
                :type="type"
                :size="size"
                :start-placeholder="placeholder[0]"
                format="yyyy-MM-dd HH:mm"
                :end-placeholder="placeholder[1]"
                unlink-panels
                value-format="timestamp"
                :picker-options="pickerOptions"
                style="width: 300px;padding: 3px 3px 3px 0"
                @change="$emit('change')"
        />
    </div>
</template>

<script>
    export default {
        name: 'MixSearch',
        props: {
            placeholder: {
                type: [String, Array],
                default: '搜索关键字'
            },
            type: {
                type: String,
                default: 'input',
                validator(value) {
                    return ['input', 'select', 'datetimerange'].indexOf(value) !== -1
                }
            },
            options: {
                type: [Array, Function],
                default: () => []
            },
            size: {
                type: String,
                default: 'small'
            },
            width: {
                type: Number,
                default: 200
            },
            label: {
                type: String,
                default: ''
            },
            value: {
                type: String | Number,
                default: ''
            },
            start: {
                type: Number,
                default: undefined
            },
            end: {
                type: Number,
                default: undefined
            }
        },

        watch: {
            options(newV, oldV) {
                console.log('5965656')
                console.log(newV)
                console.log(oldV)
            }
        },

        data() {
            return {
                renderOptions:[], // 若filter为异步加载数据 此处为真实渲染的数据
                pickerOptions: {
                    shortcuts: [
                        {
                            text: '最近一天',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24)
                                picker.$emit('pick', [start, end])
                            }
                        },
                        {
                            text: '最近三天',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
                                picker.$emit('pick', [start, end])
                            }
                        },
                        {
                            text: '最近一周',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                                picker.$emit('pick', [start, end])
                            }
                        }, {
                            text: '最近一个月',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                                picker.$emit('pick', [start, end])
                            }
                        }, {
                            text: '最近三个月',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                                picker.$emit('pick', [start, end])
                            }
                        },
                        {
                            text: '最近六个月',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
                                picker.$emit('pick', [start, end])
                            }
                        },
                        {
                            text: '最近一年',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
                                picker.$emit('pick', [start, end])
                            }
                        }
                    ]
                }
            }
        },
        computed: {
            /* 真实情景下可能有异步加载的数据 使用renderOptions代替真实数据 数据格式遵循{label,value} */
            filterOptions() {
                if(this.renderOptions.length > 0){
                    return this.renderOptions
                }else {
                    if (typeof this.options === 'function') {
                        /* 异步加载选项 */
                        this.options().then(res=>{
                            this.renderOptions = res
                        })
                        return []
                    } else {
                        // 非异步加载选项
                        return this.options
                    }
                }
            },

            val: {
                set(e) {
                    this.$emit('update:value', e)
                },
                get() {
                    return this.value
                }
            },

            time: {
                set(e) {
                    if (e && Array.isArray(e)) {
                        this.$emit('update:start', parseInt(e[0] / 1000))
                        this.$emit('update:end', parseInt(e[1] / 1000))
                    } else {
                        this.$emit('update:start', null)
                        this.$emit('update:end', null)
                    }
                },

                get() {
                    return (this.start && this.end) ? [parseInt(this.start * 1000), parseInt(this.end * 1000)] : null
                }
            }
        }
    }
</script>

<template>
    <el-dialog
        :title="title"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="650px"
        center
    >
        <el-form size="small" ref="ruleForm" :model="form" :rules="formRules" label-width="100px"
                 style="width: 80%;margin: 0 auto">
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="form.phone"/>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="form.remark"/>
            </el-form-item>
            <el-row type="flex" justify="end" align="center">
                <el-button size="small" plain @click="visible = false">取消</el-button>
                <el-button size="small" :loading="submitting" type="primary" @click="submitForm">{{ submitting ? '提交中' : '确认' }}
                </el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
/* 兑换管理员新增编辑窗口 */
export default {
    name: 'exchange-admin-dialog',
    data() {
        const validatorPhone = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('手机号不能为空'))
            } else if (!/^1\d{10}$/.test(value)) {
                callback(new Error('手机号格式错误'))
            } else {
                callback()
            }
        }
        return {
            title: '',
            callback: undefined, //回调函数
            visible: false, // 显示详情
            submitting: false, // 提交状态
            form: {},
            formRules: {
                phone: [
                    {required: true, message: '手机号不能为空', trigger: 'blur'},
                    {validator: validatorPhone, trigger: 'blur'},
                ],
            },

        }
    },

    methods: {
        /* 提交form表单 */
        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.submitting = true
                    const params = {...this.form}
                    const url = params.id ? '/api/shopping/manage/admin/update' : '/api/shopping/manage/admin/add'
                    try {
                        const {data: res} = await this.$http({
                            method: 'POST',
                            url: url,
                            data: {...params},
                        })
                        if (res.code === 200) {
                            this.$message.success('操作成功')
                            this.callback && this.callback()
                            this.visible = false
                        } else {
                            this.$message.error(res.msg)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                    this.submitting = false
                }
            })
        },

        open(payload = {}) {
            const {title = '新增兑换管理员', params = {}, callback} = payload
            this.callback = callback
            this.title = title
            this.form = {}
            if (Object.keys(params).length > 0) {
                this.form = Object.assign({}, this.form, params)
            }
            this.visible = true
        },
    }
}
</script>

<template>
  <div class="page-org-layer flex">
    <div class="page-body flex-item" style="min-width: 1200px">
      <div style="padding-bottom: 10px">
        <el-page-header @back="goBack" content="部门管理"></el-page-header>
      </div>
      <el-table
        ref="table"
        :data="tableData"
        row-key="id"
        stripe
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        style="width: 100%; margin-bottom: 20px"
        :loading="loading"
      >
        <el-table-column prop="layerName" label="单位名称"></el-table-column>
        <el-table-column prop="adminName" label="管理员">
          <template slot-scope="scope">{{ null == scope.row.adminName ? '-' : scope.row.adminName }}</template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号">
          <template slot-scope="scope">{{ null == scope.row.phone ? '-' : scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button :disabled="scope.row.level == 3" @click="handleAdd(scope.row)" type="text" size="small"
              >新增下级</el-button
            >
            <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button :disabled="scope.row.lastLayer == -1" @click="handleDel(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        width="680px"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="80px">
          <el-form-item label="单位名称" prop="layerName">
            <el-input v-model="form.layerName" size="medium"></el-input>
          </el-form-item>
          <el-form-item label="管理员" v-show="!isAddTopLayer">
            <el-select v-model="form.admin" filterable placeholder="请选择">
              <el-option
                v-for="item in adminOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                size="medium"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog" size="medium">取 消</el-button>
          <el-button type="primary" @click="handleComfirm" size="medium">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      dialogVisible: false,
      dialogTitle: '新增下级机构',
      form: { layerName: '', lastLayer: '', admin: '' },
      value: [],
      options: [],
      adminOptions: [],
      manageCommunitys: [],
      rules: {
        layerName: [
          {
            required: true,
            message: '请输入单位名称',
            trigger: 'blur',
          },
        ],
      },
      addButtonDisable: true,
      isAddTopLayer: false,
    }
  },
  destroyed() {},
  mounted() {
    var that = this
    that.queryPageData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async queryPageData() {
      this.loading = true
      this.tableData = []
      const res = await this.$http.get(`/api/org/layer/layer?lastLayer=-1`)
      this.tableData = res.data.data
      this.addButtonDisable = this.tableData.length != 0

      this.loading = false
    },
    async load(tree, treeNode, resolve) {
      // console.log(tree, treeNode, resolve)
      const res = await this.$http.get(`/api/org/layer/layer?id=${tree.id}`)
      resolve(res.data.data)
    },
    /**
     * 新增顶级部门
     */
    handleAddTop() {
      this.form.lastLayer = ''
      this.dialogTitle = '新增顶级机构'
      this.isAddTopLayer = true
      this.dialogVisible = true
    },
    async handleAdd(val) {
      var that = this
      that.form.lastLayer = val.id
      that.dialogTitle = '新增下级机构'
      that.adminShow = false
      that.dialogVisible = true
      that.adminOptions = []
      const res = await this.$http.get(`/api/org/user/all`)
      if (res.data.code == 200) {
        that.adminOptions = res.data.data
      }
    },
    async handleEdit(val) {
      var that = this
      that.form = JSON.parse(JSON.stringify(val))
      that.dialogTitle = '编辑下级机构'
      that.dialogVisible = true
      that.adminOptions = []
      const res = await this.$http.get(`/api/org/user/all`)
      if (res.data.code == 200) {
        that.adminOptions = res.data.data
      }
    },
    handleDel(val) {
      // this.handlerParentNode()
      // return
      console.log(val)
      this.$confirm('确认删除该分组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.submitDel(val.id)
        })
        .catch(() => {})
    },
    async submitDel(val) {
      var that = this
      const res = await that.$http.delete(`/api/org/layer/${val}`)
      if (res.data.code != 200) {
        that.$message.error(res.data.msg)
      } else {
        this.handlerTableNode()
        that.queryPageData()
      }
    },
    handlerTableNode() {
      //清除table中节点缓存
      this.$refs.table.store.states.lazyTreeNodeMap = {}
      console.log('state', this.$refs.table.store.states.lazyTreeNodeMap)
    },
    handleComfirm() {
      console.log(this.form)
      var that = this
      that.$refs['form'].validate((valid) => {
        if (valid) {
          var body = {
            id: that.form.id,
            layerName: that.form.layerName,
            lastLayer: that.form.lastLayer,
            admin: that.form.admin,
          }

          console.log(body)
          console.log(that.form)
          that.submitPut(body)
        } else {
          return false
        }
      })
    },
    async submitPut(body) {
      var that = this
      if (that.dialogTitle == '编辑下级机构') {
        const res = await that.$http.put(`/api/org/layer/update`, body)
        if (res.data.code != 200) {
          that.$message.error(res.data.msg)
        } else {
          that.closeDialog()
          that.queryPageData()
        }
      } else {
        const res = await that.$http.post(`/api/org/layer/add`, body)
        if (res.data.code != 200) {
          that.$message.error(res.data.msg)
        } else {
          that.closeDialog()
          that.queryPageData()
        }
      }
    },
    closeDialog() {
      var that = this
      that.dialogVisible = false
      that.$refs['form'].resetFields()
      that.form = {
        id: null,
        name: '',
        provinceId: 330000,
        province: '浙江省',
        cityId: 330600,
        city: '绍兴市',
        area: [],
        address: '',
        manageProvinceId: 330000,
        manageProvince: '浙江省',
        manageCityId: 330600,
        manageCity: '绍兴市',
        manageArea: [],
        remark: '',
        parentId: null,
        parentName: null,
        level: null,
      }
      this.isAddTopLayer = false
    },
    handleAreaChange(value) {
      console.log(value)

      if (undefined != this.form.address && null != this.form.address && this.form.address.length > 0) {
        this.changeArea()
      }
      // var that = this
      // console.log(that.$refs['areaCascader'].getCheckedNodes()[0].pathLabels)
    },
    handleManageChange(value) {
      // console.log(value)
      // var that = this
      // console.log(that.$refs['manageCascader'].getCheckedNodes()[0].pathLabels)
    },
    //下载模板
    downloadTlp() {
      window.open('/proxy/org-layer.xlsx')
    },
  },
}
</script>

<style scoped>
.page__main .el-form .el-form-item {
  padding-bottom: 0;
}
.page-org-layer {
  width: 100%;
  min-height: 100%;
  background: #ededed;
  padding: 20px;
  box-sizing: border-box;
}
.page-body {
  padding: 20px;
  background: white;
  border-radius: 10px;
}
</style>

<template>
    <el-container class="page">
        <el-container class="main-container">
            <el-breadcrumb separator-class="el-icon-arrow-right" style="padding-bottom: 15px">
                <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-main class="main-body">
                <mix-table
                        ref="tableRef"
                        :fetch="tableOptions.fetch"
                        :columns="tableOptions.columns"
                        :is-selected="tableOptions.isSelected"
                        :search-control="tableOptions.searchControl"
                        :need-pagination="tableOptions.needPagination">
                    <template v-slot:header-right="props">
                        <el-row type="flex" align="middle">
                            <el-button type="primary" size="small" @click="goodsCreate">新增</el-button>
                        </el-row>
                    </template>
                    <template v-slot:opt>
                        <el-table-column
                                label="操作"
                                width="200"
                                fixed="right"
                                align="left"
                        >
                            <template slot-scope="scope">
                                <el-button type="text" style="color: #1890ff" @click="goodsUpdate(scope.row)">编辑
                                </el-button>
                                <el-button type="text" style="color: #1890ff"
                                           @click="openDetail(scope.row)"
                                           >兑换详情
                                </el-button>
                                <el-button type="text" style="color: #1890ff" v-if="!scope.row.status" @click="goodsUpOrDown(scope.row)">上架</el-button>
                                <el-button type="text" style="color: #1890ff" v-if="scope.row.status" @click="goodsUpOrDown(scope.row)">下架</el-button>
                                <el-button type="text" style="color: #f50" @click="goodsDelete(scope.row)"
                                           v-if="!scope.row.status"> 删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </mix-table>
                <!-- 商品编辑新增弹窗 -->
                <goods-dialog ref="dialogRef"/>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import MixTable from "../../components/mix-table/index";
    import GoodsDialog from "./dialog/goods-dialog";

    export default {
        components: {GoodsDialog, MixTable},
        data() {
            return {
                goodsTypeObj: {},
                goodsTypeOptions: [],
                tableOptions: {
                    fetch: '/api/shopping/manage/product/list',
                    columns: [
                        {
                            prop: 'productName',
                            label: '商品名称',
                            searchParams: 'keyword',
                            search: true,
                            minWidth: 240
                        },
                        {
                            prop: 'categoryId',
                            label: '商品类型',
                            width: 160,
                            filterParams: 'productCategory',
                            filters: async () => {
                                const {data: res} = await this.$http.get(`/api/product/category/list`)
                                let obj = {}
                                const options =  res.data.map(item => {
                                    obj[item.id] = item.categoryName
                                    return {label: item.categoryName, value: item.id}
                                })
                                this.goodsTypeObj = obj
                                return options
                            },
                            formatter: (row) => this.goodsTypeObj[row.categoryId] || ''
                        },
                        {
                            prop: 'productScore',
                            label: '兑换所需积分',
                            minWidth: 120
                        },
                        {
                            prop: 'providerName',
                            label: '所属供应方',
                            minWidth: 240
                        },
                        {
                            prop: 'draw',
                            label: '是否参与抽奖',
                            width: 120,
                            formatter: (row) => row.draw ? '是' : "否",
                        },
                        {
                            prop: 'residueNum',
                            label: '库存',
                            width: 100
                        },
                        {
                            prop: 'exchangeNum',
                            label: '已兑换数',
                            width: 100
                        },
                        {
                            prop: 'status',
                            label: '上架状态',
                            filterParams: 'productStatus',
                            filters: [
                                {label: '上架中', value: true}, {label: '已下架', value: false},
                            ],
                            width: 120,
                            formatter: (row) => row.status ? <div class="goods-status">上架中</div> :
                                <div class="goods-status_red">已下架</div>
                        },
                    ],
                    needPagination: true, // 开启分页
                    searchControl: true, // 搜索重置按钮
                    isSelected: false, // 多选回调
                }
            }
        },

        methods: {
            navTo(path) {
                this.$router.push(path)
            },

            /* 查看兑换详情 */
            openDetail(row){
                let {id,categoryId} = row
                this.navTo(`/org-goods/goods-exchange?productId=${id}&categoryId=${categoryId}`)
            },
            /* 商品添加 */
            goodsCreate() {
                this.$refs.dialogRef.open({
                    title: '新增商品',
                    params: {},
                    callback: () => {
                        this.$refs.tableRef.refresh()
                    }
                })
            },

            /* 商品编辑 */
            goodsUpdate(row) {
                this.$refs.dialogRef.open({
                    title: '编辑商品',
                    params: {...row},
                    callback: () => {
                        this.$refs.tableRef.refresh()
                    }
                })
            },

            /* 商品上架、商品下架 */
            goodsUpOrDown(row){
                this.$msgbox({
                    type: 'warning',
                    title: '温馨提示',
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    message: `确认${!row.status?'上架':'下架'}？`
                }).then(async () => {
                    const {data: res} = await this.$http({
                        method: 'POST',
                        url: '/api/shopping/manage/product/update',
                        data: {...row,status:!row.status},
                    })
                    if (res.code === 200) {
                        this.$message.success(`${!row.status?'上架':'下架'}成功`)
                        this.$refs.tableRef.refresh()
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(err => console.log(err))
            },

            /* 商品删除 */
            goodsDelete(row) {
                const params = []
                if (row.keys) {
                    if (row.keys.length === 0) {
                        this.$message.warning('请选择要删除的数据')
                        return;
                    }
                    row.keys.map(item => {
                        params.push(item.id)
                    })
                } else {
                    params.push(row.id)
                }
                this.$msgbox({
                    type: 'warning',
                    title: '温馨提示',
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    message: `确认删除？`
                }).then(async () => {
                    const {data: res} = await this.$http({
                        method: 'POST',
                        url: '/api/shopping/manage/product/del',
                        data: params,
                    })
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        this.$refs.tableRef.refresh()
                        this.$emit('change')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).catch(err => console.log(err))
            }
        }
    }
</script>

<style scoped>
    .page {
        width: 100%;
        height: 100%;
    }

    .main-container {
        padding: 25px;
        background: #ededed;
        display: flex;
        flex-direction: column;
    }

    .main-body {
        background-color: white;
        border-radius: 20px;
        padding: 24px 20px;
        box-sizing: border-box;
        font-size: 18px;
    }

    .goods-status {
        position: relative;
        padding-left: 15px;
    }

    .goods-status:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #2cec2c;
    }

    .goods-status_red {
        position: relative;
        padding-left: 15px;
    }

    .goods-status_red:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #FF5A00;
    }
</style>
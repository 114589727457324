<template>
    <el-dialog
            :title="title"
            :visible.sync="visible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width="650px"
            center
    >
        <el-form size="small" ref="ruleForm" :model="form" :rules="formRules" label-width="100px"
                 style="width: 80%;margin: 0 auto">
            <el-form-item label="供应方名称" prop="providerName">
                <el-input v-model="form.providerName"/>
            </el-form-item>
            <el-form-item label="供应方类型" prop="providerType">
                <el-select v-model="form.providerType" placeholder="请选择" style="width: 100%">
                    <el-option v-for="(item,index) in providerTypeOptions"
                               :key="index"
                               :label="item"
                               :value="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="所在区域" prop="areaArray">
                <el-cascader
                        style="width: 100%"
                        :placeholder="form.id?`${form.providerArea}${form.providerStreet}`:'请选择'"
                        ref="areaCascader"
                        v-model="form.areaArray"
                        :options="areaOptions"
                        @change="onAreaChange"
                        size="medium"
                        filterable
                ></el-cascader>
            </el-form-item>
            <el-form-item label="接收地址" prop="receiveAddress">
                <el-input v-model="form.receiveAddress"/>
            </el-form-item>
            <el-row type="flex" justify="end" align="center">
                <el-button size="small" plain @click="visible = false">取消</el-button>
                <el-button size="small" :loading="submitting" type="primary" @click="submitForm">{{ submitting ?
                    '提交中' : '确认' }}
                </el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        name: 'goods-provider-dialog',
        data() {
            return {
                title: '',
                callback: undefined, //回调函数
                visible: false, // 显示详情
                submitting: false, // 提交状态
                form: {},
                formRules: {
                    providerName: [
                        {required: true, message: '请输入供应方名称', trigger: 'blur'},
                    ],
                    providerType: [
                        {required: true, message: '请选择供应方类型', trigger: 'blur'},
                    ],
                    areaArray: [
                        {required: true, message: '请选择区域', trigger: 'blur'},
                    ],
                    receiveAddress: [
                        {required: true, message: '请输入接收地址', trigger: 'blur'},
                    ],
                },
                areaOptions: [],
                providerTypeOptions: []
            }
        },

        mounted() {
            this.initDialog()
        },

        methods: {
            onAreaChange(){
                let areaLabels =
                    this.$refs['areaCascader'].getCheckedNodes()[0].pathLabels
                const providerArea = areaLabels[0]
                const providerStreet = areaLabels[1]
                this.$set(this.form,'providerArea',providerArea)
                this.$set(this.form,'providerStreet',providerStreet)
            },

            async initDialog() {
                let res = null
                res = await this.$http.get(`/api/community/cascader/sx/street`)
                this.areaOptions = res.data.data.children
                res = await this.$http.get(`/api/shopping/manage/provider/types`)
                this.providerTypeOptions = res.data.data
            },

            /* 提交form表单 */
            submitForm() {
                this.$refs.ruleForm.validate(async (valid) => {
                    if (valid) {
                        this.submitting = true
                        const params = {...this.form}
                        const url = params.id ? '/api/shopping/manage/provider/update' : '/api/shopping/manage/provider/add'
                        try {
                            const {data: res} = await this.$http({
                                method: 'POST',
                                url: url,
                                data: {...params},
                            })
                            if (res.code === 200) {
                                this.$message.success('操作成功')
                                this.callback && this.callback()
                                this.visible = false
                            } else {
                                this.$message.error(res.msg)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                        this.submitting = false
                    }
                })
            },

            open(payload = {}) {
                const {title = '新增供应商', params = {}, callback} = payload
                this.callback = callback
                this.title = title
                this.form = {}
                if (Object.keys(params).length > 0) {
                    this.form = Object.assign({}, this.form, params)
                }
                // TODO 后台接口存储的是字符串 所以编辑回显显示在placeholder上 编辑时不校验
                if (!params.id) {
                    this.$set(this.formRules, 'areaArray', {required: true, message: '请选择区域', trigger: 'blur'})
                } else {
                    this.$delete(this.formRules, 'areaArray')
                }
                this.visible = true
            },
        }
    }
</script>

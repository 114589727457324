<template>
    <el-container class="page">
        <el-container class="main-container">
            <el-breadcrumb separator-class="el-icon-arrow-right" style="padding-bottom: 15px">
                <el-breadcrumb-item>供应方管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-main class="main-body">
                <mix-table
                        ref="tableRef"
                        :fetch="tableOptions.fetch"
                        :columns="tableOptions.columns"
                        :is-selected="tableOptions.isSelected"
                        :search-control="tableOptions.searchControl"
                        :need-pagination="tableOptions.needPagination">
                    <template v-slot:header-right="props">
                        <el-row type="flex" align="middle">
                            <el-button type="primary" size="small" @click="providerCreate">新增</el-button>
                            <el-button type="danger" size="small" plain  @click="providerDelete(props)">批量删除</el-button>
                        </el-row>
                    </template>
                    <template v-slot:opt>
                        <el-table-column
                                label="操作"
                                width="100"
                                fixed="right"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <el-button type="text" style="color: #1890ff" @click="providerUpdate(scope.row)">编辑</el-button>
                                <el-button type="text" style="color: #ef5c2e" @click="providerDelete(scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </mix-table>
                <goods-provider-dialog ref="dialogRef" />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import MixTable from "../../components/mix-table/index";
    import GoodsProviderDialog from "./dialog/goods-provider-dialog";
    // 供应方管理
    export default {
        components: {GoodsProviderDialog, MixTable},
        data() {
            return {
                tableOptions: {
                    fetch: '/api/shopping/manage/provider/list',
                    columns: [
                        {prop: 'id', label: '序号', width: 220},
                        {prop: 'providerName', label: '供应方名称', searchParams: 'keyword', search: true, width: 240},
                        {prop: 'providerType', label: '类型',
                        filterParams: 'providerType',
                        filters: async () => {
                            const {data: res} = await this.$http.get(`/api/shopping/manage/provider/types`)
                            return res.data.map(item=>{
                                return {value:item,label:item}
                            })
                        },width: 240},
                        {prop: 'receiveAddress', label: '供应方所处乡镇街道', formatter:(row)=> `浙江省绍兴市${row.providerArea}${row.providerStreet}`,minWidth: 240},
                    ],
                    needPagination: true, // 开启分页
                    searchControl: true, // 搜索重置按钮
                    isSelected: true, // 多选回调
                }
            }
        },
        methods: {
           /* 新增供应商 */
            providerCreate(){
                this.$refs.dialogRef.open({
                    title: '新增供应商',
                    params: {},
                    callback: () => {
                        this.$refs.tableRef.refresh()
                    }
                })
            },
            /* 编辑供应商 */
            providerUpdate(row){
                const orgId = this.$cookie.get('uid')
                this.$refs.dialogRef.open({
                    title: '编辑供应商',
                    params: {...row,orgId},
                    callback: () => {
                        this.$refs.tableRef.refresh()
                    }
                })
            },

            /* 供应方删除 */
            providerDelete(row) {
                const params = []
                if (row.keys) {
                    if (row.keys.length === 0) {
                        this.$message.warning('请选择要删除的数据')
                        return;
                    }
                    row.keys.map(item => {
                        params.push(item.id)
                    })
                } else {
                    params.push(row.id)
                }
                this.$msgbox({
                    type: 'warning',
                    title: '温馨提示',
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    message: `确认删除？`
                }).then(async () => {
                    const {data: res} = await this.$http({
                        method: 'POST',
                        url: '/api/shopping/manage/provider/del',
                        data: params,
                    })
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        // TODO 缺删除成功回调逻辑
                        this.$refs.tableRef.refresh()
                        this.$emit('change')
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(err => console.log(err))
            }
        }
    }
</script>

<style scoped>
    .page {
        width: 100%;
        height: 100%;
    }

    .main-container {
        padding: 25px;
        background: #ededed;
        display: flex;
        flex-direction: column;
    }

    .main-body {
        background-color: white;
        border-radius: 20px;
        padding: 24px 20px;
        box-sizing: border-box;
        font-size: 18px;
    }
</style>
import axios from 'axios'
import router from '@/router'
import {
	Message
} from "element-ui";
import Vue from 'vue'

const http = axios.create({
	timeout: 1000 * 600,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json; charset=utf-8'
	}
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
	config.url = "/proxy" + config.url
	config.headers['Authorization'] = Vue.cookie.get('Authorization')
	return config
}, err => {
	return Promise.reject(err)
})

/**
 * 返回拦截
 */
http.interceptors.response.use(response => {
	return response
}, err => {
	if (err.response.status == 401 || err.response.status == 402) {
		Vue.cookie.delete('Authorization')
		Message.error('未登录或验证失败')
		router.push({
			path: '/login'
		})
		return Promise.reject(err)
	}
	return Promise.reject(err)
})

export default http

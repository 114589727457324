<template>
    <el-dialog
            :title="title"
            :visible.sync="visible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width="650px"
            center
    >
        <el-form size="small" ref="ruleForm" :model="form" :rules="formRules" label-width="120px"
                 style="width: 80%;margin: 0 auto">
            <el-form-item label="商品名称" prop="productName">
                <el-input v-model="form.productName"/>
            </el-form-item>
            <el-form-item label="商品图片">
                <el-upload
                        action="/proxy/api/file/upload"
                        :show-file-list="false"
                        :disabled="form.productImg && form.productImg != ''"
                        style="width: 100px;height: 60px"
                        :on-success="handleAvatarSuccess"
                        :on-remove="handleRemove"
                >
                    <div class="upload">
                        <el-image
                                v-if="form.productImg"
                                class="upload-img"
                                style="width: 100%; height: 100%"
                                :src=" `/proxy/${ form.productImg}`"
                                :fit="'scale-down'"></el-image>
                        <i class="el-icon-plus" v-else></i>
                        <i class="el-icon-delete icon-delete" v-if="form.productImg" @click.stop="handleRemove"></i>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="商品类型" prop="categoryId">
                <el-select v-model="form.categoryId" placeholder="请选择" style="width: 100%">
                    <el-option v-for="(item,index) in goodsTypeOptions"
                               :key="index"
                               :label="item.categoryName"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="供应方" prop="providerId">
                <el-select v-model="form.providerId" placeholder="请选择" style="width: 100%">
                    <el-option v-for="(item,index) in providerOptions"
                               :key="index"
                               :label="item.label"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="兑换所需积分" prop="productScore">
                <el-input v-model="form.productScore"/>
            </el-form-item>
            <el-form-item label="商品库存" prop="quantity" v-if="!form.id">
                <el-input v-model="form.quantity"/>
            </el-form-item>
            <el-form-item label="是否上架" prop="status">
                <el-switch
                        v-model="form.status"
                        active-text="是"
                        inactive-text="否">
                </el-switch>
            </el-form-item>
            <el-form-item label="是否参与抽奖" prop="draw">
                <el-switch
                        v-model="form.draw"
                        active-text="是"
                        inactive-text="否"
                >
                </el-switch>
            </el-form-item>
            <el-form-item label="抽奖概率" prop="drawChance" v-if="form.draw">
                <el-input v-model="form.drawChance" />
            </el-form-item>
            <el-form-item label="抽奖所需积分" prop="drawScore" v-if="form.draw">
                <el-input v-model="form.drawScore"/>
            </el-form-item>
            <el-row type="flex" justify="end" align="center">
                <el-button size="small" plain @click="visible = false">取消</el-button>
                <el-button size="small" :loading="submitting" type="primary" @click="submitForm">{{ submitting ?
                    '提交中' : '确认' }}
                </el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
    /* 商品弹窗 */
    export default {
        name: 'goods-dialog',
        data() {
            /* 库存校验 */
            const checkQuantity = function (rule, value, callback) {
                const reg = /^\d+$/
                if (!value && value !== 0) {
                    return callback(new Error('请输入库存数量'))
                } else if (!reg.test(value) || parseInt(value) < 0 || parseInt(value) >= 100000000) {
                    return callback(new Error('请输入0-99999999之间的数字'))
                } else {
                    callback()
                }
            }
            /* 积分校验 */
            const checkScore = (rule, value, callback) => {
                const reg = /^\d+$/
                if (!value && value !== 0) {
                    return callback(new Error('请输入所需积分'))
                } else if (!reg.test(value) || parseInt(value) < 0 || parseInt(value) >= 100000000) {
                    return callback(new Error('请输入0-99999999之间的数字'))
                } else {
                    callback()
                }
            }
            /* 抽奖概率校验 */
            const checkChance = (rule, value, callback) => {
                // const reg = /^(0.\d+|0|1)$/;
                const reg =  /^1$|^0(\.\d{1,5})?$/
                if (!value && value !== 0) {
                    return callback(new Error('请输入抽奖概率'))
                } else if (!reg.test(value)) {
                    return callback(new Error('请输入0-1之间的数字,最多支持5位小数'))
                } else {
                    callback()
                }
            }
            return {
                title: '',
                callback: undefined, //回调函数
                visible: false, // 显示详情
                submitting: false, // 提交状态
                form: {},
                formRules: {
                    productName: [
                        {required: true, message: '请输入商品名称', trigger: 'blur'},
                    ],
                    categoryId:[
                        {required: true, message: '请选择商品类型', trigger: 'blur'},
                    ],
                    providerId: [
                        {required: true, message: '请选择供应方', trigger: 'blur'},
                    ],
                    productScore: [
                        {required: true, validator: checkScore, trigger: 'blur'},
                    ],
                    quantity: [
                        {required: true, validator: checkQuantity, trigger: 'blur', type: 'number',},
                    ],
                    draw:[
                        {required: true, message: '请选择是否参与抽奖', trigger: 'blur'},
                    ],
                    status:[
                        {required: true, message: '请选择是否上架', trigger: 'blur'},
                    ],
                    drawChance: [
                        {required: true, validator:checkChance, trigger: 'blur'},
                    ],
                    drawScore: [
                        {required: true, validator: checkScore, trigger: 'blur'},
                    ],
                },
                areaOptions: [],
                providerOptions: [],
                goodsTypeOptions:[]
            }
        },

        mounted() {
            this.initDialog()
        },

        methods: {
            handleAvatarSuccess(res, file) {
                this.$set(this.form, 'productImg', res)
            },

            handleRemove() {
                this.$delete(this.form, 'productImg')
            },

            async initDialog() {
                let res = null
                res = await this.$http.post(`/api/shopping/manage/provider/option`)
                this.providerOptions = res.data.data
                res = await this.$http.get(`/api/product/category/list`)
                this.goodsTypeOptions = res.data.data
            },

            /* 提交form表单 */
            submitForm() {
                this.$refs.ruleForm.validate(async (valid) => {
                    if (valid) {
                        this.submitting = true
                        const params = {...this.form}
                        const url = params.id ? '/api/shopping/manage/product/update' : '/api/shopping/manage/product/add'
                        try {
                            const {data: res} = await this.$http({
                                method: 'POST',
                                url: url,
                                data: {...params},
                            })
                            if (res.code === 200) {
                                this.$message.success('操作成功')
                                this.callback && this.callback()
                                this.visible = false
                            } else {
                                this.$message.error(res.msg)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                        this.submitting = false
                    }
                })
            },

            open(payload = {}) {
                const {title = '新增商品', params = {}, callback} = payload
                this.callback = callback
                this.title = title
                this.form = {status: false, draw: false}
                if (Object.keys(params).length > 0) {
                    this.form = Object.assign({}, this.form, params)
                }
                this.visible = true
            },
        }
    }
</script>
<style scoped>
    .upload {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 60px;
        border: 1px dashed #d9d9d9;
        overflow: hidden;
    }

    .upload-img {
        position: relative;
    }

    .icon-delete {
        color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999999999 !important;
        display: none;
    }

    .upload:hover .upload-img:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .upload:hover .icon-delete {
        display: block !important;
    }

</style>
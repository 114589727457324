<template>
  <div class="flex direction-column" style="display:inline-block">
    <el-upload
      action="/proxy/api/file/upload/tmp/orgSpecial"
      :headers="headers"
      :show-file-list="false"
      :before-upload="onBefore"
      :on-success="onSuccess"
      :on-error="onError"
    >
      <el-button type="primary">批量导入</el-button>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible" width="500px" :before-close="handleClose" @close="handleCloseDialog">
      <div class="flex direction-column justify-center align-center">
        <div v-if="0 == data.error">成功导入{{ data.success }}人</div>
        <div v-else>成功导入{{ data.success }}人，失败{{ data.error }}人</div>

        <div style="margin-bottom: 20px;margin-top:30px">
          <el-button
            type="primary"
            @click="dialogVisible = false"
            style="width: 200px;margin-bottom: 20px;"
            v-if="0 == data.error"
          >
            完成
          </el-button>
          <el-upload
            action="/proxy/api/file/upload/tmp/orgSpecial"
            :headers="headers"
            :show-file-list="false"
            :before-upload="onBeforeAgain"
            :on-success="onSuccess"
            :on-error="onError"
          >
            <el-button type="primary" style="width: 200px;">再次导入</el-button>
          </el-upload>
        </div>

        <div style="margin-bottom:30px;">
          <el-button type="primary" v-if="data.error > 0" @click="downloadTlp" style="width: 200px;"
            >下载失败记录</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from '../../utils/base.js'
import Vue from 'vue'
export default {
  props: {
    importDataUrl: {
      type: String,
      required: true
    },
    layerId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      data: {},
      headers:{
        Authorization:this.$cookie.get('Authorization')
      }}
  },
  destroyed() {},
  mounted() {},
  methods: {
    onBefore() {
      console.log('onBefore')
      this.loading = base.loading(this)
    },
    onBeforeAgain() {
      console.log('onBeforeAgain')
      this.dialogVisible = false
      this.onBefore()
    },
    async onSuccess(response) {
      console.log('onSuccess')
      const res = await this.$http.post(this.importDataUrl + `?layerId=${this.layerId}`, response)
      if (200 != res.data.code) {
        this.$message.error(res.data.msg)
        this.loading.close()
        return
      }
      this.data = res.data.data
      console.log(res)
      this.loading.close()
      this.dialogVisible = true
    },
    onError() {
      console.log('onError')
      this.loading.close()
    },
    handleClose(done) {
      console.log('handleClose')
      console.log(done)
      done()
    },
    handleCloseDialog() {
      console.log('handleCloseDialog')
      this.$emit('import-finish')
    },
    downloadTlp() {
      window.open(this.data.path + `&token=${Vue.cookie.get('Authorization')}`)
    }
  }
}
</script>

<style scoped></style>
